/* eslint-disable no-undef */
const environment = process.env.REACT_APP_ENV;

const getConfig = (mode) => {
	switch (mode) {
		case "prod":
			return {
				socket: "wss://zeroq.cl/socket",
				login: "https://zeroq.cl/login",
				apiOrigin: "https://zeroq.cl",
				jitsiTokenProvider:
					"https://zeroq.cl/service/jwt_jitsi/api/v1/jwt/main",
				jwtJitsi: "https://zeroq.cl/service/jwt_jitsi/api/v1/jwt",
				localWebApi: "https://aws.zeroq.cl/services/turn-o-matic",
				webLocal: "https://aws.zeroq.cl/",
				socketPath: "/services/turn-o-matic/connect",
				reservationsUrl:
					"https://zeroq.cl/services/reservations/api/v2",
				recaptchaKey: "6LfvHdwUAAAAAAJXXtsHBwgqsM2Aas2ZswIPTj2B",
				firebase: {
					apiKey: "AIzaSyDvGIz-V8AjyG0RA5XA-_oumxtkfFXZ5UI",
					authDomain: "zeroqueue-985.firebaseapp.com",
				},
				turnOMaticApi: "https://aws.zeroq.cl/services/turn-o-matic",
				tokenProviderBeams:
					"https://zeroq.cl/services/push_notifications/beams/auth",
				beamsInstanceId: "6faa0794-ee68-4481-a53f-cf193a87ee22",
				featureFlags: "prod_WASKuXYKhTLhqUATe8SZUnZDhYd1ylUWNP0d31c9c",
				subDomain: "",
				lambdaUri: "https://services.zeroq.cl",
				mode: "production",
			};
		case "staging":
			return {
				socket: "wss://zeroq.cl/socket",
				login: "https://zeroq.cl/login",
				apiOrigin: "https://zeroq.cl",
				jitsiTokenProvider:
					"https://staging.zeroq.cl/service/jwt_jitsi/api/v1/jwt/main",
				jwtJitsi:
					"https://staging.zeroq.cl/service/jwt_jitsi/api/v1/jwt",
				localWebApi: "https://aws.zeroq.cl/services/turn-o-matic",
				webLocal: "https://aws.zeroq.cl/",
				socketPath: "/service/turn-o-matic/connect",
				reservationsUrl:
					"https://staging.zeroq.cl/services/reservations/api/v2",
				recaptchaKey: "6LfvHdwUAAAAAAJXXtsHBwgqsM2Aas2ZswIPTj2B",
				firebase: {
					apiKey: "AIzaSyDvGIz-V8AjyG0RA5XA-_oumxtkfFXZ5UI",
					authDomain: "zeroqueue-985.firebaseapp.com",
				},
				turnOMaticApi: "https://aws.zeroq.cl/services/turn-o-matic",
				tokenProviderBeams:
					"https://staging.zeroq.cl/services/push_notifications/beams/auth",
				beamsInstanceId: "6faa0794-ee68-4481-a53f-cf193a87ee22",
				featureFlags:
					"staging_jRBvuZfiSB5cSUZpp2KEAhH7tqOqILKqJe9ItBnqT1c",
				subDomain: "",
				lambdaUri: "https://services.zeroq.cl",
				mode: "staging",
			};
		case "qa":
			return {
				socket: "wss://qa.zeroq.cl/socket",
				login: "https://qa.zeroq.cl/login",
				apiOrigin: "https://qa.zeroq.cl",
				jitsiTokenProvider:
					"https://qa.zeroq.cl/service/jwt_jitsi/api/v1/jwt/main",
				jwtJitsi: "https://qa.zeroq.cl/service/jwt_jitsi/api/v1/jwt",
				localWebApi: "https://qa.zeroq.cl/service/turn-o-matic",
				webLocal: "https://qa.zeroq.cl/",
				socketPath: "/service/turn-o-matic/connect",
				reservationsUrl:
					"https://qa.zeroq.cl/services/reservations/api/v2",
				recaptchaKey: "6LfvHdwUAAAAAAJXXtsHBwgqsM2Aas2ZswIPTj2B",
				firebase: {
					apiKey: "AIzaSyDvGIz-V8AjyG0RA5XA-_oumxtkfFXZ5UI",
					authDomain: "zeroqueue-985.firebaseapp.com",
				},
				turnOMaticApi: "https://qa.zeroq.cl/services/turn-o-matic",
				featureFlags: "lab_2Bh4J85zB8YzRlCiOwPgftVUSP5MWqiEI4JG6mqk",
				lambdaUri: "https://services.qa.zeroq.cl",
				tokenProviderBeams:
					"https://qa.zeroq.cl/services/push_notifications/beams/auth",
				beamsInstanceId: "6faa0794-ee68-4481-a53f-cf193a87ee22",
				subDomain: "qa.",
				mode: "qa",
			};
		case "dev":
			return {
				socket: "wss://dev.zeroq.cl/socket",
				login: "https://dev.zeroq.cl/login",
				apiOrigin: "https://dev.zeroq.cl",
				jitsiTokenProvider:
					"https://dev.zeroq.cl/service/jwt_jitsi/api/v1/jwt/main",
				jwtJitsi: "https://dev.zeroq.cl/service/jwt_jitsi/api/v1/jwt",
				localWebApi: "https://dev.zeroq.cl/services/turn-o-matic",
				reservationsUrl:
					"https://dev.zeroq.cl/services/reservations/api/v2",
				recaptchaKey: "6LfvHdwUAAAAAAJXXtsHBwgqsM2Aas2ZswIPTj2B",
				firebase: {
					apiKey: "AIzaSyDvGIz-V8AjyG0RA5XA-_oumxtkfFXZ5UI",
					authDomain: "zeroqueue-985.firebaseapp.com",
				},
				turnOMaticApi: "https://dev.zeroq.cl/services/turn-o-matic",
				tokenProviderBeams:
					"https://dev.zeroq.cl/services/push_notifications/beams/auth",
				webLocal: "https://dev.zeroq.cl/",
				socketPath: "/services/turn-o-matic/connect",
				featureFlags: "lab_2Bh4J85zB8YzRlCiOwPgftVUSP5MWqiEI4JG6mqk",
				beamsInstanceId: "6faa0794-ee68-4481-a53f-cf193a87ee22",
				lambdaUri: "https://services.dev.zeroq.cl",
				subDomain: "dev.",
				mode: "development",
			};
		case "lab":
			return {
				socket: "wss://zeroq.cl/socket",
				login: "https://lab.zeroq.cl/login",
				apiOrigin: "https://lab.zeroq.cl",
				jitsiTokenProvider:
					"https://lab.zeroq.cl/service/jwt_jitsi/api/v1/jwt/main",
				jwtJitsi: "https://lab.zeroq.cl/service/jwt_jitsi/api/v1/jwt",
				localWebApi: "https://lab.zeroq.cl/service/turn-o-matic",
				webLocal: "https://lab.zeroq.cl/",
				socketPath: "/service/turn-o-matic/connect",
				reservationsUrl:
					"https://lab.zeroq.cl/services/reservations/api/v2",
				recaptchaKey: "6LfvHdwUAAAAAAJXXtsHBwgqsM2Aas2ZswIPTj2B",
				firebase: {
					apiKey: "AIzaSyDvGIz-V8AjyG0RA5XA-_oumxtkfFXZ5UI",
					authDomain: "zeroqueue-985.firebaseapp.com",
				},
				turnOMaticApi: "https://staging2.zeroq.cl/service/turn-o-matic",
				tokenProviderBeams:
					"https://lab.zeroq.cl/services/push_notifications/beams/auth",
				featureFlags: "lab_2Bh4J85zB8YzRlCiOwPgftVUSP5MWqiEI4JG6mqk",
				beamsInstanceId: "6faa0794-ee68-4481-a53f-cf193a87ee22",
				lambdaUri:
					"https://rhz1djmo93.execute-api.sa-east-1.amazonaws.com/dev",
				subDomain: "lab.",
				mode: "lab",
			};
		default:
			return {
				socket: "wss://zeroq.cl/socket",
				login: "https://zeroq.cl/login",
				apiOrigin: "https://zeroq.cl",
				jitsiTokenProvider:
					"https://staging.zeroq.cl/service/jwt_jitsi/api/v1/jwt/main",
				jwtJitsi:
					"https://staging.zeroq.cl/service/jwt_jitsi/api/v1/jwt",
				localWebApi: "https://staging2.zeroq.cl/service/turn-o-matic",
				webLocal: "https://staging2.zeroq.cl/",
				socketPath: "/service/turn-o-matic/connect",
				imgToDownload: "https://zeroq.cl/logo-zeroq.png",
				beamsInstanceId: "6faa0794-ee68-4481-a53f-cf193a87ee22",
				featureFlags: "prod_WASKuXYKhTLhqUATe8SZUnZDhYd1ylUWNP0d31c9c",
				lambdaUri: "https://services.zeroq.cl",
				mode: "staging",
			};
	}
};

const config = getConfig(environment);

export default config;

// unused url's
// {
//   "apiUrlV2": "https://zeroq.cl/api/v2",
//   "reservationsUrl": "https://zeroq.cl/services/reservations/api/v2",
//   "recaptchaKey": "6LfvHdwUAAAAAAJXXtsHBwgqsM2Aas2ZswIPTj2B",
//   "firebase": {
//     "apiKey": "AIzaSyDvGIz-V8AjyG0RA5XA-_oumxtkfFXZ5UI",
//     "authDomain": "zeroqueue-985.firebaseapp.com"
//   },

// const url = {
// 	devDNS: "https://dev.zeroq.cl",
// 	socket: "wss://zeroq.cl/socket",
// 	apiURL: "https://zeroq.cl/api/v1",
// 	apiOrigin: "https://zeroq.cl",
// 	stagingApiOrigin: "https://staging.zeroq.cl",
// 	loginPasswordURL: "https://zeroq.cl/login/password",
// 	loginURL: "https://zeroq.cl/login",
// 	stagingloginURL: "https://staging.zeroq.cl/login",
// 	labLoginURL: "https://lab.zeroq.cl/login",
// 	reservationsUrl: "https://zeroq.cl/services/reservations/api/v2",
// 	reservationsUrlStaging:
// 		"https://staging.zeroq.cl/services/reservations/api/v2",
// 	recaptchaKey: "6LdDCmQcAAAAABKp5gq9Xg-bqvPMGMS-5_yzbnZ5",
// 	recaptchaKey1: "6LfvHdwUAAAAAAJXXtsHBwgqsM2Aas2ZswIPTj2B",
// 	beamsInstanceId: "6faa0794-ee68-4481-a53f-cf193a87ee22",
// 	webModuloServiceStaging: "https://staging2.zeroq.cl/service/turn-o-matic",
// 	webModuloServiceProd: "https://aws.zeroq.cl/services/turn-o-matic",
// 	labOriginUrl: "https://lab.zeroq.cl",
// 	tokenProviderBeams:
// 		"https://staging.zeroq.cl/service/push_notifications/beams/auth",
// 	firebase: {
// 		apiKey: "AIzaSyDvGIz-V8AjyG0RA5XA-_oumxtkfFXZ5UI",
// 		authDomain: "zeroqueue-985.firebaseapp.com",
// 	},
// 	featureFlags: {
// 		prod: "prod_WASKuXYKhTLhqUATe8SZUnZDhYd1ylUWNP0d31c9c",
// 		staging: "staging_jRBvuZfiSB5cSUZpp2KEAhH7tqOqILKqJe9ItBnqT1c",
// 		lab: "lab_2Bh4J85zB8YzRlCiOwPgftVUSP5MWqiEI4JG6mqk",
// 	},
// };
